<template>
  <b-navbar-nav class="nav">
    <b-nav-item
        v-for="(bookmark, index) in iconData"
        :id="`bookmark-${index}`"
        :key="index"
        :to="bookmark.route"
    >
      <feather-icon
          :icon="bookmark.icon"
          size="21"
          class="text-white"
      />
      <b-tooltip
          triggers="hover"
          :target="`bookmark-${index}`"
          :title="bookmark.title"
          :delay="{ show: 40, hide: 50 }"
      />
    </b-nav-item>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Cari Kartlar"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item
          to="/cariGruplar"
      >
        <feather-icon
            icon="UsersIcon"
            class="mr-75"
            size="18"
        />
        Cari Gruplar
      </b-dropdown-item>
      <b-dropdown-item
          to="/cariListe/0"
      >
        <feather-icon
            icon="UserIcon"
            class="mr-75"
            size="18"
        />
        Cari Kartlar
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Stoklar"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item
          to="/stokGrup"
      >
        <feather-icon
            icon="ShoppingBagIcon"
            class="mr-75"
            size="18"
        />
        Stok Grupları
      </b-dropdown-item>
      <b-dropdown-item
          to="/stokListesi/0"
      >
        <feather-icon
            icon="ListIcon"
            class="mr-75"
            size="18"
        />
        Stok Listesi
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Satışlar"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item
          to="/yeniSatis"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-75"
            size="18"
        />
        Yeni Satış Oluştur
      </b-dropdown-item>
      <b-dropdown-item
          to="/satislar"
      >
        <feather-icon
            icon="ArchiveIcon"
            class="mr-75"
            size="18"
        />
        Satış Faturaları
      </b-dropdown-item>
      <b-dropdown-item
          to="/proformalar"
      >
        <feather-icon
            icon="StarIcon"
            class="mr-75"
            size="18"
        />
        Teklif & Proforma
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Giderler"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item
          to="/yeniAlis"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-75"
            size="18"
        />
        Yeni Alış Faturası
      </b-dropdown-item>
      <b-dropdown-item
          to="/alislar"
      >
        <font-awesome-icon icon="fa-solid fa-receipt" class="mr-75 fa-xl"/>
        Alış Faturaları
      </b-dropdown-item>
      <b-dropdown-item
          to="/giderGruplar"
      >
        <feather-icon
            icon="TagIcon"
            class="mr-75"
            size="18"
        />
        Gider Grupları
      </b-dropdown-item>
      <b-dropdown-item
          to="/giderListesi/0"
      >
        <feather-icon
            icon="MinusIcon"
            class="mr-75"
            size="18"
        />
        Giderler
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Depo"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item to="/depolar">
        <feather-icon
            icon="BriefcaseIcon"
            class="mr-75"
            size="18"
        />
        Depo
      </b-dropdown-item>
      <b-dropdown-item to="/depokomisyonuyeleri">
        <feather-icon
            icon="UsersIcon"
            class="mr-75"
            size="18"
        />
        Depo Komisyon Üyeleri
      </b-dropdown-item>
      <b-dropdown-divider class="custom-dropdown-divider"/>
      <b-dropdown-item to="/yeniFis">
        <feather-icon
            icon="FilePlusIcon"
            class="mr-75"
            size="18"
        />
        Fiş Oluştur
      </b-dropdown-item>
      <b-dropdown-item to="/fislistesi">
        <feather-icon
            icon="FileTextIcon"
            class="mr-75"
            size="18"
        />
        Fiş Listesi
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Kasa ve Banka"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item
          to="/kasa"
      >
        <feather-icon
            icon="BriefcaseIcon"
            class="mr-75"
            size="18"
        />
        Kasa
      </b-dropdown-item>
      <b-dropdown-item
          to="/banka"
      >
        <feather-icon
            icon="BarChart2Icon"
            class="mr-75"
            size="18"
        />
        Banka Havaleleri
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Raporlar"
        variant="primary"
        class="ml-50 my-drop-item"
    >
      <b-dropdown-item
          to="/rapor"
      >
        <feather-icon
            icon="SearchIcon"
            class="mr-75"
            size="18"
        />
        Rapor Oluştur
      </b-dropdown-item>
    </b-dropdown>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem, BDropdown, BDropdownDivider
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BDropdownDivider,
    BDropdown,
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
  },
  setup() {
    const iconData = [
      {
        title: 'DashBoard',
        route: { name: 'dashBoard' },
        icon: 'GridIcon',
        isBookmarked: true,
      }]
    const data = []
    const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
    const bookmarks = ref(searchAndBookmarkData.pages.data.filter(page => page.isBookmarked))
    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({
      data: { pages: searchAndBookmarkDataPages.value },
      searchLimit: 6,
    })

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    watch(filteredData, val => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })

    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push(suggestion.route)
      resetsearchQuery()
    }

    const toggleBookmarked = item => {
      // Find Index of item/page in bookmarks' array
      const pageIndexInBookmarks = bookmarks.value.findIndex(i => i.route === item.route)

      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks.value[pageIndexInBookmarks].isBookmarked = false
        bookmarks.value.splice(pageIndexInBookmarks, 1)
      } else {
        bookmarks.value.push(item)
        bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
      }
    }

    return {
      iconData,
      data,
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      toggleBookmarked,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
  directives: {
    Ripple,
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.custom-dropdown-divider {
  hr {
    width: 100%;
  }
}

.dropdown-menu .dropdown-item {
  width: 100% !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
<style>
.b-nav-dropdown a {
  color: white !important;
}

.dropdown-menu a {
  color: #7c7987 !important;
}

.dropdown-item {
  padding: 0.35rem 3rem 0.35rem 15px !important;
}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
  top: 34px !important;
}

.btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-of-type {
  background-color: transparent !important;
  border-color: transparent !important;
}

.header-navbar .navbar-container ul.navbar-nav li {
  align-items: center !important;
  display: flex !important;
}

.my-drop-item .dropdown-item {
  width: 100% !important;
}

</style>
